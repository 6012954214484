import NetworkAccountMainMenu from 'modules/network-accounts/menus/NetworkAccountMainMenu';
import CustomUrlMainMenu from 'modules/UrlModule/menus/CustomUrlMainMenu';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PostbacksMainMenu from 'modules/PostbacksModule/menus/PostbacksMainMenu';
import ReportMainMenu from 'modules/report/menus/ReportMainMenu';

const SecondaryMenu = (props) => {
  return (
    <Switch>
      <Route path='/accounts' component={NetworkAccountMainMenu} />
      <Route path='/report' component={ReportMainMenu} />
      <Route path='/postbacks' component={PostbacksMainMenu} />
      <Route path='/url' component={CustomUrlMainMenu} />
    </Switch>
  );
};

export default SecondaryMenu;
