import useFastspringButton from '@lib/fastspring/useFastSpringButton';
import { Button, Card } from 'antd';
import { useChooseFreeSubscriptionMutation } from 'modules/billing/api/billing.api';
import React from 'react';
import useWorkspaceBilling from 'store/redux/hooks/useWorkspaceBilling';

const PlanCard = (props) => {
  const {
    plan,
    yearly,
    isCurrent,
    update,
    button_text,
    disabled = false,
    disable_button = false,
  } = props;
  const { hasActivePlan, hasFreePlan, isLoading: isBillinLoading } = useWorkspaceBilling();

  const { isScriptLoading, isSubscriptionLoading, checkout } = useFastspringButton({
    update: hasActivePlan() && !hasFreePlan(),
    planCode: plan.code,
  });

  const [chooseFreePlanQuery, { isLoading: isChooseFreeLoading }] =
    useChooseFreeSubscriptionMutation();

  let yearlyDisabled = false;
  if (yearly && !plan.yearly_price) {
    yearlyDisabled = true;
  }

  const loading =
    isScriptLoading || isSubscriptionLoading || isBillinLoading || isChooseFreeLoading;

  console.log(isScriptLoading, isSubscriptionLoading, isBillinLoading, isChooseFreeLoading);

  const choosePlan = (code) => {
    if (plan.is_free) {
      chooseFreePlanQuery();
    } else {
      checkout(code);
    }
  };

  return (
    <div className='h-full shadow-lg bg-white flex justify-between flex-col rounded-lg w-70 px-5 py-8 transition duration-500 ease-in-out hover:shadow-2xl hover:border-gray-200 transform cursor-pointer '>
      <div>
        <div
          className='flex flex-col sm:h-44 ustify-between'
          style={{ borderBottom: '1px solid rgba(209, 213, 219)' }}
        >
          <div className='mb-4'>
            <div className='flex justify-center text-center gap-2'>
              <div className='gap-1 grid'>
                <span className='text-3xl font-bold font-montHeavy'>{plan.name}</span>
                <span className='text-sm text-LightBlue font-firaGoMedium !leading-4'>
                  {plan.description}
                </span>
              </div>
            </div>
          </div>
          <div className='pb-5 m-auto'>
            <div className='flex items-end gap-1.5 justify-end'>
              <span className='text-4xl font-black font-montHeavy text-orange'>
                ${yearly && plan.yearly_price ? plan.yearly_price : plan.price}
              </span>
              <span className='text-sm'>/</span>
              <span className='text-xs tracking-wide font-semibold font-firaGoLight'>
                {yearly ? 'Yearly' : 'Month'}
              </span>
            </div>

            <span className='flex justify-center text-xs mt-2 font-semibold'>
              {plan.price_caption}
            </span>
          </div>
        </div>
        <div className=' gap-2 pt-5  grid'>
          {plan.tags.map((feature, index) => {
            return (
              <div className='flex items-center gap-1' key={index}>
                <img className='w-5' src='/icons/success.svg' alt='' />
                <span className='text-xs font-firaGoMedium'>{feature}</span>
              </div>
            );
          })}
        </div>
      </div>
      {!disable_button ? (
        <Button
          loading={loading}
          disabled={yearlyDisabled || isCurrent || disabled}
          type='primary'
          className='w-28 cursor-pointer m-auto mt-5 flex justify-center items-center bg-orange border-transparent py-3 rounded-full focus:outline-none text-white text-sm font-medium'
          onClick={() => {
            choosePlan(yearly ? `${plan.code}-yearly` : plan.code);
          }}
        >
          {isCurrent ? 'Current Plan' : button_text}
        </Button>
      ) : null}
    </div>
  );
};

export default PlanCard;
