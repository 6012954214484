import React, { Fragment } from 'react';
import { Page } from 'components/layouts/components';
import { Alert, Card, Space, Tag, Timeline, Tooltip } from 'antd';
import UserJourneyPageFilter from 'modules/report/filters/UserJourneyPageFilter';
import collectApi from 'modules/report/api/collectApi';
import {
  mdiAccountAlert,
  mdiCellphoneIphone,
  mdiClockOutline,
  mdiCursorDefaultClick,
  mdiEye,
  mdiGoogleChrome,
  mdiMicrosoftWindows,
  mdiTagPlusOutline,
} from '@mdi/js';
import { Icon } from '@mdi/react';
import { Typography } from 'antd';
import useWorkspace from 'store/redux/hooks/useWorkspace';

const { Text, Link } = Typography;

const UserJourneyPage = (props) => {
  const [fetchUserJourneyQuery, { isLoading, data }] =
    collectApi.endpoints.UserJourney.useLazyQuery();

  const onFilter = (values) => {
    fetchUserJourneyQuery(values).unwrap();
  };

  const { workspace } = useWorkspace();

  const items = [];

  const addEvents = (events) => {
    events?.forEach((event) => {
      const data = {
        label: (
          <div className='flex justify-end flex-wrap gap-2'>
            <TagWithTooltip
              tooltip={event.timestamp}
              icon={<Icon path={mdiClockOutline} size={'12px'} />}
            >
              {event.time_human}
            </TagWithTooltip>
          </div>
        ),
        children: (
          <div className='flex flex-col gap-1'>
            {}
            {event.type === 'pageview' ? (
              <Fragment>
                <div>
                  <Text>Url: </Text> <Link href={event.page_url}>{event.page_url}</Link>
                </div>
                <div>
                  <Text>Referrer: </Text>
                  <Link href={event.referrer_url}>{event.referrer_url}</Link>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div>
                  <Text>Event: </Text>
                  <div className='max-w-[100px] inline-block'>
                    <Text code>{event.type}</Text>
                  </div>
                </div>
                {event.referrer_url && (
                  <div>
                    <Text>Referrer: </Text>
                    <Link href={event.referrer_url}>{event.referrer_url}</Link>
                  </div>
                )}
              </Fragment>
            )}
            {event.payload?.value ? (
              <div className='max-w-[100px]'>
                <TagWithTooltip
                  tooltip="Event's value"
                  icon={<Icon path={mdiTagPlusOutline} size={'12px'} />}
                >
                  {event.payload.value} {event.payload?.currency ?? workspace.currency}
                </TagWithTooltip>
              </div>
            ) : null}
            {event.payload && <EventPayload payload={event.payload} />}
          </div>
        ),
        dot:
          event.type === 'pageview' ? (
            <Icon path={mdiEye} size={'16px'} />
          ) : (
            <Icon path={mdiCursorDefaultClick} size={'16px'} />
          ),
      };

      items.push(data);
    });
  };

  data?.sessions?.forEach((session) => {
    addEvents(session.events);

    const data = {
      label: (
        <div className='flex justify-end flex-wrap gap-2'>
          <TagWithTooltip
            tooltip={session.timestamp}
            icon={<Icon path={mdiClockOutline} size={'12px'} />}
          >
            {session.time_human}
          </TagWithTooltip>
          {session.os && (
            <TagWithTooltip
              tooltip='Operating System'
              icon={<Icon path={mdiMicrosoftWindows} size={'12px'} />}
            >
              {session.os}
            </TagWithTooltip>
          )}
          {session.device && (
            <TagWithTooltip
              tooltip='Device'
              icon={<Icon path={mdiCellphoneIphone} size={'12px'} />}
            >
              {session.device}
            </TagWithTooltip>
          )}
          {session.url && (
            <TagWithTooltip tooltip='URL' icon={<Icon path={mdiCellphoneIphone} size={'12px'} />}>
              {session.url.name}
            </TagWithTooltip>
          )}
          {session.browser && (
            <TagWithTooltip tooltip='Browser' icon={<Icon path={mdiGoogleChrome} size={'12px'} />}>
              {session.browser}
            </TagWithTooltip>
          )}
        </div>
      ),
      children: (
        <div className='flex flex-col gap-1'>
          <div>New session:</div>
          <div>
            <Text>Url: </Text>
            <Link href={session.page_url}>{session.page_url}</Link>
          </div>
          <div>
            <Text>Referrer: </Text>
            <Link href={session.referrer_url}>{session.referrer_url}</Link>
          </div>

          <div>
            <Text>User: </Text>

            <Text code>{session.user?.id}</Text>
            {session.user?.identifier ? (
              <Text code>
                {typeof session.user?.identifier === 'object'
                  ? JSON.stringify(session.user?.identifier)
                  : session.user?.identifier}
              </Text>
            ) : null}
          </div>

          <div>
            <Text>Website: </Text>
            <Link href={session.website?.host}>{session.website?.host}</Link>

            <Text code>{session.website?.hash}</Text>
          </div>
          <div>
            <Text code>IP Address:{session.ip}</Text>
          </div>
        </div>
      ),
      dot: <Icon path={mdiAccountAlert} size={'16px'} color='blue' />,
    };

    items.push(data);
  });

  return (
    <Page className='flex flex-col gap-4'>
      <Card>
        <UserJourneyPageFilter isLoading={isLoading} onSubmit={onFilter} />
      </Card>
      {items.length ? (
        <Card>
          <Timeline mode='left' items={items} />
        </Card>
      ) : (
        <Card>
          <Alert message='No data found' type='info' />
        </Card>
      )}
    </Page>
  );
};

const TagWithTooltip = (props) => {
  const { tooltip, toolTipProps = {}, children, ...rest } = props;

  return (
    <Tooltip title={tooltip} {...toolTipProps}>
      <Tag className='flex items-center gap-2' color='default' {...rest}>
        {children}
      </Tag>
    </Tooltip>
  );
};

const EventPayload = ({ payload }) => {
  if (!payload) return null;

  const filterKeys = ['value', 'currency'];

  if (typeof payload === 'string') {
    return <Text keyboard>payload</Text>;
  }

  if (typeof payload === 'object') {
    return (
      <Space direction='vertical' size={0}>
        {Object.keys(payload)
          .filter((f) => !filterKeys.includes(f))
          .map((key) => (
            <div key={key}>
              <Text code>
                {key}: {payload[key]}
              </Text>
            </div>
          ))}
      </Space>
    );
  }

  return null;
};

UserJourneyPage.propTypes = {};

export default UserJourneyPage;
